<template>
  <div class="organizer-view">
    <OrganizerDashboard />
  </div>
</template>

<script>
import OrganizerDashboard from '@/components/OrganizerDashboard.vue'

export default {
  components: {
    OrganizerDashboard,
  },
}
</script>
<style scoped>
.organizer-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}
</style>
