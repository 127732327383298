<template>
  <div class="registration-submissions">
    <!-- Add this notification element at the top of your template -->
    <transition name="fade">
      <div
        v-if="showNotificationFlag"
        class="notification"
        :class="notificationType"
      >
        {{ notificationMessage }}
      </div>
    </transition>

    <h2>{{ t('registration.submissions') }}</h2>
    <div v-if="loading">Loading submissions...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="filters">
        <input
          v-model="searchQuery"
          :placeholder="t('general.search')"
          @input="filterSubmissions"
        />
      </div>
      <button @click="exportToCSV" class="export-button">
        {{ t('registration.exportToCSV') }}
      </button>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th @click="sortBy('id')">
                ID
                <span
                  v-if="sortKey === 'id'"
                  :class="['sort-indicator', sortOrder]"
                ></span>
              </th>
              <th
                v-for="field in allFields"
                :key="field.id"
                @click="sortBy(field.name)"
              >
                {{ field.name }}
                <span v-if="field.required" class="required-indicator">*</span>
                <span
                  v-if="sortKey === field.name"
                  :class="['sort-indicator', sortOrder]"
                ></span>
              </th>
              <th @click="sortBy('created_at')">
                Date
                <span
                  v-if="sortKey === 'created_at'"
                  :class="['sort-indicator', sortOrder]"
                ></span>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(submission, index) in sortedSubmissions"
              :key="submission.id"
              class="submission-row"
            >
              <td>{{ index + 1 }}</td>
              <td v-for="field in allFields" :key="field.id">
                <template v-if="isCheckbox(field)">
                  <input
                    type="checkbox"
                    :checked="submission.submission_data[field.name] === 'on'"
                    disabled
                  />
                </template>
                <template v-else>
                  {{ submission.submission_data[field.name] || '' }}
                </template>
              </td>
              <td>{{ formatDate(submission.created_at) }}</td>
              <td>
                <button
                  @click="openSubmissionEdit(submission.id)"
                  class="edit-button"
                >
                  Edit
                </button>
                <button
                  @click="deleteSubmission(submission.id)"
                  class="delete-button"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal for SubmissionEdit -->
    <div v-if="selectedSubmissionId" class="modal">
      <div class="modal-content">
        <SubmissionEdit
          :submissionId="selectedSubmissionId"
          :formId="formId"
          @close="closeSubmissionEdit"
          @update="updateSubmission"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import SubmissionEdit from '@/components/UI/Registration/SubmissionEdit.vue'
import { useNotification } from '@/composables/useNotification'

export default {
  components: {
    SubmissionEdit,
  },
  setup() {
    const route = useRoute()
    const formId = ref(Number(route.params.formId))
    const submissions = ref([])
    const formGroups = ref([])
    const loading = ref(true)
    const error = ref(null)
    const { t } = useI18n()
    const {
      showNotificationFlag,
      notificationMessage,
      notificationType,
      showNotification,
    } = useNotification()

    const allFields = computed(() => {
      return formGroups.value.flatMap((group) => group.fields)
    })
    const searchQuery = ref('')
    const filteredSubmissions = ref([])
    const sortKey = ref('')
    const sortOrder = ref('asc')

    const loadSubmissions = async () => {
      loading.value = true
      try {
        const [formResponse, submissionsResponse] = await Promise.all([
          axios.get(`/registration/${formId.value}`),
          axios.get(`/registration/${formId.value}/submissions`),
        ])

        formGroups.value = formResponse.data.groups
        submissions.value = submissionsResponse.data
        filterSubmissions() // Apply initial filtering
        loading.value = false
        // showNotification(t('registration.submissionsLoaded'), 'success')
      } catch (err) {
        error.value = 'Failed to load submissions'
        loading.value = false
        showNotification(t('registration.failedToLoadSubmissions'), 'error')
      }
    }

    const sortBy = (key) => {
      if (sortKey.value === key) {
        sortOrder.value = sortOrder.value === 'desc' ? 'asc' : 'desc'
      } else {
        sortKey.value = key
        sortOrder.value = 'desc'
      }
    }

    const sortedSubmissions = computed(() => {
      return [...filteredSubmissions.value].sort((a, b) => {
        let aValue =
          sortKey.value === 'id' || sortKey.value === 'created_at'
            ? a[sortKey.value]
            : a.submission_data[sortKey.value]
        let bValue =
          sortKey.value === 'id' || sortKey.value === 'created_at'
            ? b[sortKey.value]
            : b.submission_data[sortKey.value]

        // Handle checkbox values
        if (aValue === 'on' || bValue === 'on') {
          aValue = aValue === 'on' ? 1 : 0
          bValue = bValue === 'on' ? 1 : 0
        } else {
          // For non-checkbox values, convert to lowercase if they're strings
          if (typeof aValue === 'string') aValue = aValue.toLowerCase()
          if (typeof bValue === 'string') bValue = bValue.toLowerCase()
        }

        if (aValue < bValue) return sortOrder.value === 'asc' ? -1 : 1
        if (aValue > bValue) return sortOrder.value === 'asc' ? 1 : -1
        return 0
      })
    })

    const filterSubmissions = () => {
      if (!searchQuery.value) {
        filteredSubmissions.value = submissions.value
        return
      }

      const query = searchQuery.value.toLowerCase()
      filteredSubmissions.value = submissions.value.filter((submission) => {
        return (
          Object.values(submission.submission_data).some((value) =>
            String(value).toLowerCase().includes(query)
          ) || submission.id.toString().includes(query)
        )
      })
    }

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString()
    }

    const exportToCSV = () => {
      try {
        let csvContent = 'data:text/csv;charset=utf-8,'

        // CSV headers
        let headers = [
          'ID',
          ...allFields.value.map((field) => field.name),
          'Date',
        ]
        csvContent += headers.join(',') + '\n'

        // CSV rows
        filteredSubmissions.value.forEach((submission, index) => {
          let row = [
            index + 1,
            ...allFields.value.map((field) => {
              let value = submission.submission_data[field.name]
              // Convert to string and replace commas with semicolons
              return String(value || '').replace(/,/g, ';')
            }),
            formatDate(submission.created_at),
          ]
          csvContent += row.join(',') + '\n'
        })

        // Create and trigger download
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute(
          'download',
          `submissions_${formId.value}_${new Date().toISOString()}.csv`
        )
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        showNotification(t('registration.exportSuccess'), 'success')
      } catch (err) {
        console.error('Error exporting to CSV:', err)
        showNotification(t('registration.exportError'), 'error')
      }
    }

    const selectedSubmissionId = ref(null)

    const openSubmissionEdit = (submissionId) => {
      selectedSubmissionId.value = submissionId
    }

    const closeSubmissionEdit = () => {
      selectedSubmissionId.value = null
    }

    const updateSubmission = (updatedSubmission) => {
      const index = submissions.value.findIndex(
        (s) => s.id === updatedSubmission.id
      )
      if (index !== -1) {
        submissions.value[index] = updatedSubmission
        showNotification(t('registration.submissionUpdated'), 'success')
      } else {
        showNotification(t('registration.submissionUpdateFailed'), 'error')
      }
      closeSubmissionEdit()
    }

    const isCheckbox = (field) => {
      return field.type === 'checkbox'
    }

    const deleteSubmission = async (submissionId) => {
      if (confirm(t('registration.confirmDelete'))) {
        try {
          await axios.delete(
            `/registration/${formId.value}/submissions/${submissionId}`
          )
          submissions.value = submissions.value.filter(
            (s) => s.id !== submissionId
          )
          filterSubmissions() // Re-apply filtering after deletion
          showNotification(t('registration.submissionDeleted'), 'success')
        } catch (err) {
          console.error('Error deleting submission:', err)
          showNotification(t('registration.deletionError'), 'error')
        }
      }
    }

    onMounted(loadSubmissions)

    return {
      formId,
      submissions,
      formGroups,
      allFields,
      loading,
      error,
      loadSubmissions,
      formatDate,
      exportToCSV,
      t,
      searchQuery,
      filteredSubmissions,
      filterSubmissions,
      sortBy,
      sortKey,
      sortOrder,
      sortedSubmissions,
      selectedSubmissionId,
      openSubmissionEdit,
      closeSubmissionEdit,
      updateSubmission,
      isCheckbox,
      showNotificationFlag,
      notificationMessage,
      notificationType,
      deleteSubmission,
    }
  },
}
</script>

<style scoped>
.registration-submissions {
  padding: 20px;
}

.form-selector {
  margin-bottom: 20px;
}

.form-selector label {
  margin-right: 10px;
}

.form-selector select {
  padding: 5px;
}

.table-container {
  max-height: 600px; /* Adjust this value as needed */
  overflow-y: auto;
  border: 1px solid #ddd;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 2px; /* Reduced padding */
  text-align: left;
  font-size: 0.8em; /* Slightly smaller font size */
}

th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

td:last-child {
  border-right: 1px solid white;
}

.required-indicator {
  color: red;
  margin-left: 2px; /* Reduced margin */
}

.export-button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.export-button:hover {
  background-color: #45a049;
}

.filters {
  margin-bottom: 20px;
}

.filters input {
  padding: 8px;
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .table-container {
    max-height: 400px;
  }

  th,
  td {
    padding: 3px; /* Further reduced padding for mobile */
  }

  .export-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .filters input {
    margin-bottom: 10px;
  }
}

.sort-indicator {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.sort-indicator.asc {
  border-bottom: 4px solid #000;
}

.sort-indicator.desc {
  border-top: 4px solid #000;
}

.edit-button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}

.edit-button:hover {
  background-color: #45a049;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
}

/* Add these styles for the notification */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.notification.success {
  background-color: #2ecc71;
}

.notification.error {
  background-color: #e74c3c;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.delete-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 5px;
}

.delete-button:hover {
  background-color: #c82333;
}

.submission-row:hover {
  background-color: #ececec;
}
</style>