<template>
  <div class="registrations">
    <!-- Remove the standalone submissions link -->
    
    <transition name="fade">
      <div
        v-if="showNotificationFlag"
        class="notification"
        :class="notificationType"
      >
        {{ notificationMessage }}
      </div>
    </transition>

    <!-- Registration Form List Component -->
    <RegistrationList
      v-if="!showRegistrationFormForm"
      :registrationForms="registrationForms"
      @edit="editRegistrationForm"
      @show-embed="showRegistrationFormEmbedCode"
      @delete="deleteRegistrationForm"
      @create-registration-form="toggleRegistrationFormForm"
      @show-submissions="showSubmissions"
    />

    <!-- Registration Form Form Component -->
    <RegistrationForm
      v-if="showRegistrationFormForm"
      :registrationForm="registrationForm"
      :editingRegistrationForm="editingRegistrationForm"
      @save="saveRegistrationForm"
      @cancel="cancelRegistrationFormEdit"
    />

    <!-- Registration Form Embed Code Modal -->
    <transition name="fade">
      <div
        v-if="showRegistrationFormEmbed"
        class="modal-overlay"
        @click="closeRegistrationFormEmbedModal"
      >
        <div class="modal-content" @click.stop>
          <RegistrationEmbedCode
            :registrationForm="selectedRegistrationForm"
            @close="closeRegistrationFormEmbedModal"
            @copy-embed-code="copyRegistrationFormEmbedCode"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import RegistrationList from './UI/Registration/RegistrationList.vue'
import RegistrationForm from './UI/Registration/RegistrationForm.vue'
import RegistrationEmbedCode from './UI/Registration/RegistrationEmbedCode.vue'
import { useNotification } from '@/composables/useNotification'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    RegistrationList,
    RegistrationForm,
    RegistrationEmbedCode,
  },
  setup() {
    const router = useRouter()
    const registrationForms = ref([])
    const registrationForm = ref({
      id: null,
      name: '',
      groups: [],
      enableManagerRegistration: false, // Add this line
    })
    const editingRegistrationForm = ref(false)
    const showRegistrationFormForm = ref(false)
    const showRegistrationFormEmbed = ref(false)
    const selectedRegistrationForm = ref(null)
    const { t } = useI18n()
    const {
      showNotificationFlag,
      notificationMessage,
      notificationType,
      showNotification,
    } = useNotification()

    const fetchRegistrationForms = async () => {
      try {
        const response = await axios.get('/registration')
        registrationForms.value = response.data
      } catch (error) {
        console.error('Error fetching registration forms:', error)
        showNotification(
          'Failed to fetch registration forms. Please try again later.',
          'error'
        )
      }
    }

    const toggleRegistrationFormForm = () => {
      showRegistrationFormForm.value = !showRegistrationFormForm.value
      if (!showRegistrationFormForm.value) {
        resetRegistrationForm()
      }
    }

    const resetRegistrationForm = () => {
      registrationForm.value = {
        id: null,
        name: '',
        groups: [],
        enableManagerRegistration: false, // Add this line
      }
      editingRegistrationForm.value = false
    }

    const saveRegistrationForm = async (formData) => {
      try {
        // Ensure all groups have names
        formData.groups = formData.groups.map((group, index) => ({
          ...group,
          name: group.name || `Group ${index + 1}`,
        }))

        // If editing an existing form, use PUT method
        if (formData.id) {
          await axios.put(`/registration/${formData.id}`, formData)
        } else {
          await axios.post('/registration', formData)
        }
        await fetchRegistrationForms()
        toggleRegistrationFormForm()
        showNotification('Registration form saved successfully!', 'success')
      } catch (error) {
        console.error('Error saving registration form:', error)
        showNotification(
          'Failed to save registration form. Please try again.',
          'error'
        )
      }
    }

    const editRegistrationForm = async (form) => {
      try {
        const response = await axios.get(`/registration/${form.id}`)
        registrationForm.value = response.data
        editingRegistrationForm.value = true
        showRegistrationFormForm.value = true
      } catch (error) {
        console.error('Error fetching registration form details:', error)
        showNotification(
          'Failed to fetch registration form details. Please try again.',
          'error'
        )
      }
    }

    const deleteRegistrationForm = async (formId) => {
      if (confirm(t('registration.deleteRegistrationFormConfirm'))) {
        try {
          await axios.delete(`/registration/${formId}`)
          await fetchRegistrationForms()
          showNotification('Registration form deleted successfully!', 'success')
        } catch (error) {
          console.error('Error deleting registration form:', error)
          showNotification(
            'Failed to delete registration form. Please try again.',
            'error'
          )
        }
      }
    }

    const showRegistrationFormEmbedCode = (form) => {
      selectedRegistrationForm.value = form
      showRegistrationFormEmbed.value = true
    }

    const closeRegistrationFormEmbedModal = () => {
      showRegistrationFormEmbed.value = false
    }

    const copyRegistrationFormEmbedCode = async (embedCodeText) => {
      try {
        await navigator.clipboard.writeText(embedCodeText)
        showNotification(
          'Registration form embed code copied to clipboard!',
          'success'
        )
        closeRegistrationFormEmbedModal()
      } catch (err) {
        console.error('Error copying registration form embed code:', err)
        showNotification(
          'Failed to copy registration form embed code.',
          'error'
        )
      }
    }

    const cancelRegistrationFormEdit = () => {
      if (confirm(t('general.cancelConfirm'))) {
        resetRegistrationForm()
        showRegistrationFormForm.value = false
      }
    }

    const showSubmissions = (formId) => {
      router.push(`/registration-submissions/${formId}`)
    }

    onMounted(fetchRegistrationForms)

    return {
      registrationForms,
      registrationForm,
      editingRegistrationForm,
      showRegistrationFormForm,
      showRegistrationFormEmbed,
      selectedRegistrationForm,
      showNotificationFlag,
      notificationMessage,
      notificationType,
      toggleRegistrationFormForm,
      saveRegistrationForm,
      editRegistrationForm,
      deleteRegistrationForm,
      showRegistrationFormEmbedCode,
      closeRegistrationFormEmbedModal,
      copyRegistrationFormEmbedCode,
      cancelRegistrationFormEdit,
      showSubmissions,
      t
    }
  },
}
</script>

<style scoped>
.registrations {
  padding: 20px;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  z-index: 1000;
}

.success {
  background-color: #4caf50;
}

.error {
  background-color: #f44336;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Remove the .registration-submissions-link styles */

/* Rest of the styles remain unchanged */
</style>