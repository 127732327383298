<template>
  <div class="registration-form">
    <h3>
      {{ editingRegistrationForm ? t('general.edit') : t('general.create') }}
      {{ t('registration.registrationForm') }}
    </h3>
    <form @submit.prevent="saveForm">
      <div class="form-group">
        <label for="formName">{{ t('registration.formName') }}</label>
        <input id="formName" v-model="formData.name" type="text" required />
      </div>

      <!-- Add this new form group for the manager registration option -->
      <div class="form-group">
        <label for="enableManagerRegistration">
          <input
            id="enableManagerRegistration"
            v-model="enableManagerRegistration"
            type="checkbox"
          />
          {{ t('registration.enableManagerRegistration') }}
        </label>
      </div>

      <!-- Update this form group for the allow embedded submissions option -->
      <div class="form-group">
        <label for="allowEmbeddedSubmissions">
          <input
            id="allowEmbeddedSubmissions"
            v-model="allowEmbeddedSubmissions"
            type="checkbox"
          />
          {{ t('registration.allowEmbeddedSubmissions') }}
        </label>
      </div>

      <div
        v-for="group in displayGroups"
        :key="group.id"
        class="form-group group"
      >
        <div class="group-header">
          <div class="group-title">
            <h3>
              {{ group.name }}
              <span
                v-if="isCheckboxOnlyGroup(group)"
                class="checkbox-only-info"
              >
                {{ t('registration.checkboxOnlyGroupInfo') }}
              </span>
            </h3>
          </div>
          <div class="group-actions">
            <button
              type="button"
              class="btn btn-secondary"
              @click="renameGroup(group.id)"
            >
              {{ t('button.rename') }}
            </button>
            <button
              v-if="group.id !== managersGroupId"
              type="button"
              class="btn btn-danger"
              @click="removeGroup(group.id)"
            >
              x
            </button>
          </div>
        </div>

        <div
          v-for="(field, fieldIndex) in group.fields"
          :key="fieldIndex"
          class="field"
        >
          <div class="field-header">
            <input
              v-model="field.name"
              type="text"
              :placeholder="t('registration.fieldName')"
              required
            />
            <select v-model="field.type">
              <option value="text">{{ t('general.text') }}</option>
              <option value="number">{{ t('general.number') }}</option>
              <option value="email">{{ t('general.email') }}</option>
              <option value="checkbox">{{ t('general.checkbox') }}</option>
              <option value="radio">{{ t('general.radio') }}</option>
              <option value="select">{{ t('general.select') }}</option>
            </select>
            <label class="checkbox-label">
              <input type="checkbox" v-model="field.required" />
              {{ t('registration.required') }}
            </label>
            <button
              v-if="group.id !== managersGroupId || fieldIndex > 1"
              type="button"
              class="btn btn-danger"
              @click="removeField(group.id, fieldIndex)"
            >
              x
            </button>
          </div>

          <div
            v-if="field.type === 'select' || field.type === 'radio'"
            class="field-options"
          >
            <div
              v-for="(option, optionIndex) in field.options"
              :key="optionIndex"
              class="option-item"
            >
              <input
                v-model="field.options[optionIndex]"
                type="text"
                :placeholder="t('general.option')"
                required
              />
              <button
                type="button"
                class="btn btn-danger"
                @click="removeOption(field, optionIndex)"
              >
                x
              </button>
            </div>
            <button
              type="button"
              class="btn btn-primary"
              @click="addOption(field)"
            >
              {{ t('button.addOption') }}
            </button>
          </div>
        </div>

        <button
          type="button"
          class="btn btn-primary"
          @click="addField(group.id)"
        >
          {{ t('button.addField') }}
        </button>
      </div>

      <button type="button" class="btn btn-primary" @click="addGroup">
        {{ t('button.addGroup') }}
      </button>
    </form>

    <!-- Floating buttons -->
    <div class="floating-buttons">
      <button @click="saveForm" class="btn btn-success">
        {{ t('button.saveForm') }}
      </button>
      <button @click="cancel" class="btn btn-cancel">
        {{ t('button.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    registrationForm: {
      type: Object,
      required: true,
    },
    editingRegistrationForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'cancel'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const formData = ref({
      ...props.registrationForm,
    })
    const enableManagerRegistration = ref(
      props.registrationForm.enable_manager_registration == '1'
    )
    const allowEmbeddedSubmissions = ref(
      props.registrationForm.allow_embedded_submissions == '1'
    )


    const managersGroupId = ref(null)

    // Add this constant at the top of the script section
    const MANAGERS_GROUP_IDENTIFIER = 'managers-group'

    // Assign unique IDs to groups if they don't have them
    const assignGroupIds = (groups) => {
      return groups.map((group) => ({
        ...group,
        id: group.id || Math.random().toString(36).substr(2, 9),
      }))
    }

    // Compute displayGroups
    const displayGroups = computed(() => formData.value.groups)

    watch(
      () => props.registrationForm,
      (newValue) => {
        formData.value = {
          ...newValue,
          groups: assignGroupIds(newValue.groups),
        }

        // Find and set the managersGroupId
        const managerGroup = newValue.groups.find(
          (group) => group.id === MANAGERS_GROUP_IDENTIFIER
        )
        if (managerGroup) {
          managersGroupId.value = managerGroup.id
        }
      },
      { immediate: true }
    )

    // Watch enableManagerRegistration changes
    watch(
      () => enableManagerRegistration.value,
      (newValue) => {
        if (newValue) {
          // Add Managers group if it doesn't exist
          if (
            !formData.value.groups.some(
              (group) => group.id === MANAGERS_GROUP_IDENTIFIER
            )
          ) {
            const managerGroup = {
              id: MANAGERS_GROUP_IDENTIFIER,
              name: t('registration.managersGroupName'),
              fields: [
                {
                  name: t('registration.managerName'),
                  type: 'text',
                  required: true,
                },
                {
                  name: t('registration.managerEmail'),
                  type: 'email',
                  required: true,
                },
              ],
            }
            formData.value.groups.push(managerGroup)
          }
          managersGroupId.value = MANAGERS_GROUP_IDENTIFIER
        } else {
          // Remove Managers group if enableManagerRegistration is set to false
          formData.value.groups = formData.value.groups.filter(
            (group) => group.id !== MANAGERS_GROUP_IDENTIFIER
          )
          managersGroupId.value = null
        }
      }
    )

    const renameGroup = (groupId) => {
      const group = formData.value.groups.find((g) => g.id === groupId)
      if (group) {
        const newName = prompt(t('registration.enterNewGroupName'), group.name)
        if (newName && newName.trim() !== '') {
          group.name = newName.trim()
        }
      }
    }

    const removeGroup = (groupId) => {
      if (groupId === managersGroupId.value) {
        // Prevent removal of the Managers group
        alert(
          'The Managers group cannot be removed while manager registration is enabled.'
        )
        return
      }
      formData.value.groups = formData.value.groups.filter(
        (g) => g.id !== groupId
      )
    }

    const addGroup = () => {
      formData.value.groups.push({
        id: Math.random().toString(36).substr(2, 9),
        name: `Group ${formData.value.groups.length + 1}`,
        fields: [],
      })
    }

    const addField = (groupId) => {
      const group = formData.value.groups.find((g) => g.id === groupId)
      if (group) {
        group.fields.push({
          name: '',
          type: 'text',
          required: false,
          options: [],
        })
      }
    }

    const removeField = (groupId, fieldIndex) => {
      const group = formData.value.groups.find((g) => g.id === groupId)
      if (group) {
        if (group.id === managersGroupId.value && fieldIndex <= 1) {
          // Prevent removal of the first two fields in the Managers group
          return
        }
        group.fields.splice(fieldIndex, 1)
      }
    }

    const addOption = (field) => {
      if (!field.options) {
        field.options = [] // Ensure options array exists
      }
      field.options.push('')
    }

    const removeOption = (field, optionIndex) => {
      field.options.splice(optionIndex, 1)
    }

    const saveForm = () => {
      formData.value.enableManagerRegistration = enableManagerRegistration.value
      formData.value.allowEmbeddedSubmissions = allowEmbeddedSubmissions.value
      emit('save', formData.value)
    }

    const cancel = () => {
      emit('cancel')
    }

    const isCheckboxOnlyGroup = (group) => {
      return (
        group.fields.length > 0 &&
        group.fields.every((field) => field.type === 'checkbox')
      )
    }

    return {
      formData,
      displayGroups,
      addGroup,
      removeGroup,
      renameGroup,
      addField,
      removeField,
      addOption,
      removeOption,
      saveForm,
      cancel,
      t,
      isCheckboxOnlyGroup,
      managersGroupId,
      enableManagerRegistration,
      allowEmbeddedSubmissions,
    }
  },
}
</script>

<style scoped>
.registration-form {
  width: 95%;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.registration-form h3 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  color: #333;
}

.form-group {
  margin-bottom: 0.5rem;
}

.group {
  /* background-color: #f9f9f9; */
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  border-bottom: 2px solid #eee;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.form-group label {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 0.9rem;
  color: #555;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.35rem 0.5rem;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  border-radius: 3px;
  transition: border-color 0.2s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.group-title {
  flex-grow: 1;
}

.group-title h3 {
  display: flex;
  align-items: center;
  margin: 0;
}

.group-actions {
  display: flex;
  align-items: center;
}

.checkbox-only-info {
  background-color: #e7f3fe;
  border: 1px solid #b6d4fe;
  color: #084298;
  padding: 4px 8px;
  margin-left: 10px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: normal;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  margin-right: 0.5rem;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.field {
  margin-bottom: 0.5rem;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.field-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
  gap: 1rem;
}

.field-header input,
.field-header select {
  margin-right: 0.25rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  font-size: 0.85rem;
}

.checkbox-label input[type='checkbox'] {
  margin-right: 0.25rem;
}

.field-options {
  margin-top: 0.25rem;
}

.option-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  gap: 1rem;
}

.option-item input {
  flex-grow: 1;
  margin-right: 0.25rem;
}

.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn-primary {
  background-color: #4a90e2;
  color: #fff;
  margin-top: 0.5rem;
}

.btn-primary:hover {
  background-color: #3a7bc8;
}

.btn-danger {
  background-color: transparent;
  color: lightcoral;
  font-size: 1.2rem;
  transform: translateY(-2px);
}

.btn-danger:hover {
  color: red;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.form-actions button {
  margin-left: 0.5rem;
}

@media (max-width: 768px) {
  .registration-form {
    padding: 0.75rem;
  }

  .field-header {
    flex-wrap: wrap;
  }

  .field-header > * {
    margin-bottom: 0.25rem;
  }

  .form-actions {
    flex-direction: column;
  }

  .form-actions button {
    margin: 0.25rem 0;
    width: 100%;
  }
}

/* Floating buttons styles */
.floating-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  padding: 10px;
}

.floating-buttons .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.floating-buttons .btn-success {
  background-color: rgba(39, 174, 96, 0.9);
  color: #fff;
  box-shadow: 0 4px 6px rgba(39, 174, 96, 0.3);
}

.floating-buttons .btn-success:hover {
  background-color: rgba(46, 204, 113, 0.9);
  box-shadow: 0 6px 8px rgba(39, 174, 96, 0.4);
}

.floating-buttons .btn-cancel {
  background-color: rgba(231, 76, 60, 0.9);
  color: #fff;
  box-shadow: 0 4px 6px rgba(231, 76, 60, 0.3);
}

.floating-buttons .btn-cancel:hover {
  background-color: rgba(192, 57, 43, 0.9);
  box-shadow: 0 6px 8px rgba(231, 76, 60, 0.4);
}

/* Adjust the main form container to account for the floating buttons */
.registration-form {
  padding-bottom: 80px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .floating-buttons {
    flex-direction: column;
    right: 10px;
    bottom: 10px;
  }

  .floating-buttons .btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
</style>
